import {
  COMPANY_PROFILE_SUBSCRIPTION_ROUTE,
  SUBSCRIPTION_ROUTE,
} from './routes';

export const services = ({ city, lang, isAuthenticated }) => [
  {
    label: lang?.common?.for_businesses,
    url: `https://www.ronorp-media.net/`,
    // url: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/place-ad-on-website?city=${city}#package-section`,
  },
  {
    // label: isAuthenticated ? lang?.common?.support_ron_orp_as_logged_in_user  : lang?.common?.support_ron_orp,
    label: isAuthenticated
      ? lang?.common?.support_ron_orp
      : lang?.common?.support_ron_orp,
    url: isAuthenticated
      ? `${process.env.NEXT_PUBLIC_FRONTEND_URL}/account-settings?tab=my_subscription&city=${city}`
      : 'https://ronorp-help.net',
  },
  {
    label: lang?.market?.advertise_on_ron_orp,
    url: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/advertise-on-ron-orp?city=${city}`,
  },
  {
    label: lang?.common?.crowdify,
    url: `https://www.crowdify.net`,
  },
];

export const quickLinks = (lang) => [
  {
    label: lang?.common?.about_ron,
    url: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/about-ron`,
  },
  {
    label: lang?.common?.contact,
    url: `https://ronorp-help.net/kontakt/`,
  },
  {
    label: lang?.common?.help,
    url: `https://ronorp-help.net/`,
  },
  {
    label: lang?.common?.gtc,
    url: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/gtc`,
  },
  {
    label: lang?.common?.privacy,
    url: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/privacy-policy`,
  },
];

export const buttons = (city, lang) => {
  return [
    {
      iconPath: '/assets/footer-pages/top-placement.svg',
      label: lang?.common?.top_placement,
      description: lang?.common?.show_up_in_market_category,
      link: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/top-placement?city=${city}`,
    },
    {
      iconPath: '/assets/footer-pages/newsletter.svg',
      label: lang?.market?.post_in_nl,
      description: lang?.common?.book_ad_in_newsletter,
      link: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/post-in-newsletter?city=${city}`,
    },
    {
      iconPath: '/assets/footer-pages/post-on-website.svg',
      label: lang?.market?.place_ad_on_website,
      description: lang?.common?.place_sponsored_ad,
      link: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/place-ad-on-website?city=${city}`,
    },
    {
      iconPath: '/assets/footer-pages/place-your-ad.svg',
      label: lang?.common?.place_your_ad,
      description: lang?.common?.use_ron_orp_free_post_ad,
      link: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/place-ad?city=${city}`,
    },
    {
      iconPath: '/assets/footer-pages/upgrade-profile.svg',
      label: lang?.common?.upgrade_profile,
      description: lang?.common?.keep_profile_updated,
      link: SUBSCRIPTION_ROUTE(city),
    },
    {
      iconPath: '/assets/footer-pages/company-profile.svg',
      label: lang?.common?.company_profile,
      description: lang?.common?.create_business_profile,
      link: COMPANY_PROFILE_SUBSCRIPTION_ROUTE(city),
    },
    {
      iconPath: '/assets/footer-pages/agency.svg',
      label: lang?.common?.agency,
      description: lang?.common?.leverage_ron_orp_know_how,
      link: `https://ronorp-agentur.net/`,
    },
    {
      iconPath: '/assets/footer-pages/media.svg',
      label: lang?.common?.media,
      description: lang?.common?.ready_to_run_full_ad_campaign,
      link: `https://www.ronorp-media.net/`,
    },
    {
      iconPath: '/assets/footer-pages/crowd-funding.svg',
      label: lang?.common?.crowd_funding,
      description: lang?.common?.sign_up_for_crowdify_webinar,
      link: `https://www.crowdify.net/`,
    },
  ];
};

export const adPreviewData = (lang) => {
  return {
    topPlacement: {
      title:
        lang?.common?.boost_advertisement_preview ||
        'Boost advertisement preview:',
      pageImage: {
        src: '/assets/footer-pages/top-placement.png',
        height: 504,
        width: 421,
      },

      cardImage: {
        src: '/assets/footer-pages/top-placement-card.png',
        height: 416,
        width: 268,
      },
    },
    onWebsite: {
      title: lang?.common?.how_to_post_and_boost || 'How to post and boost:',
      pageImage: {
        src: '/assets/footer-pages/place-ad-on-website.png',
        height: 464,
        width: 402,
      },

      cardImage: {
        src: '/assets/footer-pages/place-ad-on-website-card.png',
        height: 339,
        width: 279,
      },
    },
    onNewLetter: {
      title: lang?.common?.how_to_post_and_boost || 'How to post and boost:',
      pageImage: {
        src: '/assets/footer-pages/post-in-newsletter.png',
        height: 490,
        width: 330,
      },

      cardImage: {
        src: '/assets/footer-pages/post-in-newsletter-card.png',
        height: 168,
        width: 415,
      },
    },
  };
};
